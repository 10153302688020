<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotTwinsManage' }">孪生体管理</el-breadcrumb-item>
          <el-breadcrumb-item>孪生体编辑</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 孪生体信息 -->
          <div class="form-title">孪生体信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="孪生体分类:">{{row.park}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="孪生体名称:">{{row.digitalTwinName}}</el-form-item>
            </el-col>
          </el-row>

          <!-- 实体设备信息 -->
          <div class="form-title">实体设备信息</div>
          <el-row :gutter="20" v-if="form.deviceParts">
            <el-col :span="12">
              <el-form-item label="部件名称" prop="deviceParts">
                <el-input v-model="form.deviceParts" placeholder="请输入部件名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" size="mini" style="float:right;" @click="addDevice">选择设备</el-button>
            </el-col>
          </el-row>
          <div v-else class="unbind">未绑定设备</div>

          <!-- 已选择的设备 -->
          <div v-if="tableData.length>0">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="waterworks" label="水厂">
                <template slot-scope="scope">
                  {{ scope.row.waterworks?scope.row.waterworks:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="position" label="泵房编号">
                <template slot-scope="scope">
                  {{ scope.row.position?scope.row.position:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
              <el-table-column prop="eid" label="设备号"></el-table-column>
              <el-table-column prop="deviceTypeCode" label="设备类型">
                <template slot-scope="scope">
                  <span v-show="scope.row.deviceTypeCode=='Direct'">直连设备</span>
                  <span v-show="scope.row.deviceTypeCode=='GatewaySub'">网关子设备</span>
                  <span v-show="scope.row.deviceTypeCode=='Gateway'">网关</span>
                </template>
              </el-table-column>
              <el-table-column prop="model" label="设备型号">
                <template slot-scope="scope">
                  {{ scope.row.model?scope.row.model:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="cardNumber" label="物联网卡号">
                <template slot-scope="scope">
                  {{ scope.row.cardNumber?scope.row.cardNumber:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="equipmentBrandCode" label="设备品牌"></el-table-column>
            </el-table>
          </div>

          <!-- 孪生体属性 -->
          <div style="display:flex;align-items:center;justify-content:space-between;">
            <div class="form-title" style="margin-top: 20px;">孪生体属性</div>
            <el-button v-if="customAttr.length>0" type="primary" size="mini" @click="addCustomAttr">新增自定义属性</el-button>
          </div>
          <div v-if="customAttr.length>0">
            <div class="custom-attr" v-for="(item, index) in customAttr" :key="index">
              <div class="custom-head">
                <div class="head-title">默认属性<span v-show="item.defaultAttr"> - {{item.defaultAttr}}</span></div>
                <div class="head-close" style="margin-right:10px;" v-show="index != 0" @click="delCustomAttr(index)">×</div>
              </div>
              <div class="custom-head" style="padding:0;margin:0 10px;">
                <div>
                  <el-form-item label="默认属性">
                    <el-input v-model="item.defaultAttr" :disabled="tableData.length==0" type="text" placeholder="请输入默认属性"></el-input>
                    <!-- <el-select v-model="item.defaultAttr" placeholder="请选择默认属性">
                      <el-option
                          v-for="item in defaultAttr"
                          :key="item"
                          :label="item"
                          :value="item"
                      ></el-option>
                    </el-select> -->
                  </el-form-item>
                </div>
                <div style="margin-bottom:20px;"> 
                  <el-button type="primary" size="mini" @click="addDataContent(item.dataContent)">新增数据内容</el-button>
                </div>
              </div>
              <!-- 多个数据类型 -->
              <div v-for="(dItem, dIndex) in item.dataContent" :key="dIndex" class="data-content">
                <el-row :gutter="20">
                  <el-col :span="11">
                    <el-form-item label="数据类型">
                      <el-select v-model="dItem.dtype" :disabled="tableData.length==0" style="width:100%;" placeholder="请选择数据类型">
                        <el-option
                            v-for="item in dataType"
                            :key="item"
                            :label="item"
                            :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <el-form-item label="点表值类型">
                      <el-select v-model="dItem.formulaType" :disabled="tableData.length==0" style="width:100%;" placeholder="请选择点表值类型">
                        <el-option
                          v-for="item in pointValType"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <el-form-item label-width="20px">
                      <div class="head-close" style="margin-top:8px;" v-show="dIndex != 0" @click="delDataContent(item.dataContent, dIndex)">×</div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="11">
                    <el-form-item label="单位">
                      <el-input v-model="dItem.boolUnit" :disabled="tableData.length==0" placeholder="请输入单位"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <el-form-item label="描述">
                      <el-input v-model="dItem.boolDesc" :disabled="tableData.length==0" placeholder="请输入描述"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2"></el-col>
                </el-row>
                <el-row :gutter="20" v-if="dItem.formulaType=='default'">
                  <el-col :span="11">
                    <el-form-item label="选择点表" label-width="100px">
                      <el-button v-if="!dItem.tagDesc" class="lube-btn" type="primary" @click.prevent="selectTagItem(dItem)">选择Tag</el-button>
                      <span v-else class="close-lube-btn">
                          <span class="close-tagname">{{dItem.tagDesc}}</span>
                          <span class="el-icon-error close-lube" @click="clearTagItem(dItem)"></span>
                      </span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11"></el-col>
                  <el-col :span="2"></el-col>
                </el-row>
                <div v-if="dItem.formulaType=='formula'">
                  <div class="formula-title">
                      <div class="formula-title-left">配置公式</div>
                      <div class="formula-title-right">
                          <el-button v-show="dItem.formulaList&&dItem.formulaList.length>1" type="danger" size="small" @click="delTag(dItem.formulaList)">删除Tag</el-button>
                          <el-button type="primary" size="small" @click="addTag(dItem.formulaList)">新增Tag</el-button>
                      </div>
                  </div>
                  <el-row :gutter="20" style="margin: 30px 0px 0px 30px;">
                      <el-col :span="7">
                          <el-form-item label="公式项" label-width="70px">
                              <el-input v-model="dItem.firstInput" size="small" placeholder="请输入公式项"></el-input>
                          </el-form-item>
                      </el-col>
                      <div v-for="(val,key) in dItem.formulaList" :key="key">
                          <el-col :span="5">
                              <el-form-item label="Tag" label-width="50px">
                                  <el-button v-if="!val.tagDesc" class="lube-btn" type="primary" size="small" @click.prevent="selectTagItem(val)">选择Tag</el-button>
                                  <span v-else class="close-lube-btn">
                                      <span class="close-tagname">{{val.tagDesc}}</span>
                                      <span class="el-icon-error close-lube" @click="clearTagItem(val)"></span>
                                  </span>
                              </el-form-item>
                          </el-col>
                          <el-col :span="7">
                              <el-form-item label="公式项" label-width="70px">
                                  <el-input v-model="val.textName" size="small" placeholder="请输入公式项"></el-input>
                              </el-form-item>
                          </el-col>
                          <!-- 侧滑选择Tag -->
                          <el-drawer
                              title=""
                              :with-header="false"
                              :visible.sync="val.tagDrawer"
                              direction="rtl"
                              size="1000px">
                              <div class="drawer-title">选择点表</div>
                              <div class="drawer-content table-tree">
                                  <el-table :data="tagTableData" height="700" v-loading="abilityLoading" element-loading-spinner="el-icon-loading">
                                      <el-table-column label="勾选" width="70">
                                          <template slot-scope="scope">
                                          <el-radio-group v-model="val.tagFRadio" @change="checkTagChange(scope.row)">
                                              <el-radio :label="scope.row.id">{{''}}</el-radio>
                                          </el-radio-group>
                                          </template>
                                      </el-table-column>
                                      <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                      <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                      <el-table-column prop="pointName" label="点名"></el-table-column>
                                      <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                      <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                      <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                  </el-table>
                              </div>
                              <div class="drawer-bottom">
                                  <el-button size="small" type="primary" @click="val.tagDrawer=false">取消</el-button>
                                  <el-button size="small" type="primary" @click="formulaDrawerOK(val)" class="drawer-bottom-ok">确 定</el-button>
                              </div>
                          </el-drawer>
                      </div>
                  </el-row>
                </div>
                <!-- 数据类型为Bool -->
                <div v-if="dItem.dtype=='Bool'">
                  <!-- Tag说明 -->
                  <div class="tag-explain">
                    <span class="tag-explain-title">Tag说明</span>
                    <el-button type="primary" size="mini" @click="addTagExplain(dItem.tagExplain)">新增Tag说明</el-button>
                  </div>
                  <div v-for="(teItem, teIndex) in dItem.tagExplain" :key="teIndex">
                    <el-row :gutter="20">
                      <el-col :span="10">
                        <el-form-item label="编码">
                          <el-input v-model="teItem.boolCode" placeholder="请输入编码"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="10">
                        <el-form-item label="含义">
                          <el-input v-model="teItem.boolMeaning" placeholder="请输入含义"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="4">
                        <el-form-item label-width="20px">
                          <div class="head-close" style="margin-top:8px;" v-show="teIndex != 0" @click="delTagExplain(dItem.tagExplain, teIndex)">×</div>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <!-- 侧滑选择Tag -->
                <el-drawer
                  title=""
                  :with-header="false"
                  :visible.sync="dItem.tagDrawer"
                  direction="rtl"
                  size="1000px">
                  <div class="drawer-title">选择点表</div>
                  <div class="drawer-content table-tree">
                      <el-table :data="tagTableData" height="700" v-loading="abilityLoading" element-loading-spinner="el-icon-loading">
                          <el-table-column label="勾选" width="70">
                              <template slot-scope="scope">
                              <el-radio-group v-model="dItem.tagRadio" @change="checkTagChange(scope.row)">
                                  <el-radio :label="scope.row.id">{{''}}</el-radio>
                              </el-radio-group>
                              </template>
                          </el-table-column>
                          <el-table-column prop="number" label="序号" width="50"></el-table-column>
                          <el-table-column prop="dataType" label="数据类型"></el-table-column>
                          <el-table-column prop="pointName" label="点名"></el-table-column>
                          <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                          <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                          <el-table-column prop="cycle" label="采集周期"></el-table-column>
                      </el-table>
                  </div>
                  <div class="drawer-bottom">
                      <el-button size="small" type="primary" @click="dItem.tagDrawer=false">取消</el-button>
                      <el-button size="small" type="primary" @click="formulaDrawerOK(dItem)" class="drawer-bottom-ok">确 定</el-button>
                  </div>
                </el-drawer>
              </div>
            </div>
          </div>
          <div v-else class="unbind">未绑定设备</div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotTwinsManage')">取消</el-button>
            <el-button v-if="customAttr.length>0" type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <!-- 选择实体设备侧滑弹窗 -->
      <el-drawer
        title="选择设备"
        :with-header="false"
        :visible.sync="deviceDrawer"
        direction="rtl"
        size="1100px">
        <div class="drawer-title">选择设备</div>
        <div class="drawer-content table-tree">
          <div class="tree">
            <div class="tree-title">供应商</div>
            <el-tree
              :data="supplierData"
              node-key="supplierCode"
              @node-click="supplierClick"
              :props="supplierProps"
              :default-checked-keys="[supplierCode]"
              highlight-current
              :current-node-key="supplierCode"
              ref="supplierTree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="tree">
            <div class="tree-title">品牌</div>
            <el-tree
              :data="brandData"
              node-key="equipmentBrandCode"
              @node-click="brandClick"
              :props="brandProps"
              :default-checked-keys="[equipmentBrandCode]"
              highlight-current
              :current-node-key="equipmentBrandCode"
              ref="brandTree"
              v-loading="brandLoading" element-loading-spinner="el-icon-loading">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          
          <div class="table">
            <el-table :data="tableDataCp" height="700" v-loading="tableSubDeviceLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-radio-group v-model="deviceRadio" @change="checkDeviceChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
              <el-table-column prop="eid" label="设备号"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="deviceDrawer=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="deviceDrawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      row: {},
      form: {
        deviceParts: null
      },
      rules: {
        deviceParts:[{ required: true, message: '必填项', trigger: 'blur' }],
        defaultAttr:[{ required: true, message: '必填项', trigger: 'change' }],
        dtype:[{ required: true, message: '必填项', trigger: 'change' }],
        formulaType:[{ required: true, message: '必填项', trigger: 'change' }],
        boolOperator:[{ required: true, message: '必填项', trigger: 'change' }],
        boolTag:[{ required: true, message: '必填项', trigger: 'change' }],
      },

      // 选择设备模块
      supplierProps: {
        children: 'childTree',
        label: 'supplier'
      },
      supplierCode: '',
      brandProps: {
        children: 'childTree',
        label: 'equipmentBrand'
      },
      equipmentBrandCode: '',

      tagTableData: [],
      tagSelectObj: {},

      deviceDrawer: false,
      supplierData: [],//供应商数据
      brandData: [],//品牌数据
      brandLoading: false,//品牌Loading
      tableSubDeviceLoading: false,//列表Loading
      deviceRadio: '',//radio高亮标识
      tableDataCp: [],//侧滑设备列表数组
      tableSelectList: [],//选择设备数组
      tableSelectArr: [],//选择设备数组
      abilityLoading: false,
      tableData: [],//实体设备数组
      customAttr: [//自定义属性数组初始化
        // {defaultAttr: '',
        // dataContent: [{
        //   tagDrawer: false,
        //   tagRadio: '',
        //   dtype: '',
        //   boolOperator: 1,
        //   boolTag: '',
        //   boolUnit: '',
        //   boolDesc: '',
        //   boolTag1: '',
        //   tagCustom: [{
        //     tagDrawer: false,
        //     tagRadio: '',
        //     name: ''
        //   }],
        //   tagExplain: [{
        //     boolCode:'',
        //     boolMeaning:''
        //   }]
        // }]}
      ],
      pointValType: [
        { code: 'default', name: '默认值'},
        { code: 'formula', name: '公式'},
      ],
      defaultAttr: [],//默认属性
      dataType: ['Bool','Real'],//数据类型
      boolTags: [],//Tags列表
    };
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.tableData.length==0) return this.$message.error('请新增一个设备')
          // 自定义属性循环
          for(var i=0;i<this.customAttr.length;i++) {
            let cAi = this.customAttr[i]
            if(!cAi.defaultAttr) return this.$message.error('请输入默认属性')
            // 数据内容循环
            for(var y=0;y<cAi.dataContent.length;y++) {
              let dCy = cAi.dataContent[y]
              if(!dCy.dtype) return this.$message.error('请选择数据类型')
              if(!dCy.formulaType) return this.$message.error('请选择点表值类型')
              if(!dCy.firstInput&&dCy.formulaList[0].tagName==''&&dCy.formulaType=='formula') return this.$message.error('请配置公式')
              // Tag含义循环
              for(var k=0;k<dCy.tagExplain.length;k++) {
                if(dCy.dtype=='Bool'&&!dCy.tagExplain[k].boolCode) return this.$message.error('请输入编码')
                if(dCy.dtype=='Bool'&&!dCy.tagExplain[k].boolMeaning) return this.$message.error('请输入含义')
              }
            }
          }
          let form = {
            deviceParts: this.form.deviceParts,
            digitalTwinId: this.row.digitalTwinId,
            equipmentBrandCode: this.tableData.length>0 ? this.tableData[0].equipmentBrandCode : null,
            eid: this.tableData[0].eid,
            attributes: {}
          }
          this.customAttr.forEach((cItem,cIndex)=>{
            let dataConArr = []
            cItem.dataContent.forEach((v,k)=>{
              let obj = {}
              let formula = v.firstInput?v.firstInput:''
              v.formulaList.forEach(item=>{
                formula += item.tagName + item.textName
              })
              if(v.dtype=='Bool') {
                obj = {
                  dataType: v.dtype,
                  boolData: {
                    formulaType: v.formulaType,
                    tag: v.boolTag?v.boolTag:null,
                    tagDescribe: v.boolDesc?v.boolDesc:null,
                    unit: v.boolUnit?v.boolUnit:null,
                    formula:v.formulaType=='formula'?formula:null,
                    values: {}
                  }
                }
                v.tagExplain.forEach((val, key) => {
                  obj.boolData.values[val.boolCode] = val.boolMeaning
                })
                dataConArr.push(obj)
                return
              }
              if(v.dtype=='Real') {
                obj = {
                  dataType: v.dtype,
                  realData: {
                    formulaType: v.formulaType,
                    tag: v.boolTag?v.boolTag:null,
                    tagDescribe: v.boolDesc?v.boolDesc:null,
                    unit: v.boolUnit?v.boolUnit:null,
                    formula:v.formulaType=='formula'?formula:null
                  }
                }
                dataConArr.push(obj)
                return
              }
            })
            form.attributes[cItem.defaultAttr] = dataConArr
          })
          console.log(form)
          // return
          this.loading = true;
          this.$ajax.post("iotTwinsUpdate", form).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/iotTwinsManage");
          }).catch(err=>{
            this.loading = false;
          })
        }
      })
    },

    // 新增实体设备
    addDevice() {
      this.loadSupplier()
      this.deviceDrawer = true
    },

    // 获取供应商
    loadSupplier() {
      this.$ajax.post('iotSupplierQuery').then(res => {
        if(res.data.length>0) {
          setTimeout(()=>{ //高亮选中
            this.$refs.supplierTree.setCurrentKey(res.data[0].supplierCode)
          },200)
          this.supplierData = res.data
          this.loadBrand(res.data[0].supplierCode)

          this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
            if (this.tableSelectList.find(item => item.eid == v.eid)) {
              v.checked = true
            }else {
              v.checked = false
            }
          })
        }
      })
    },

    // 获取供应商下品牌
    loadBrand(supplierCode) {
      this.$ajax.post('supplierDeviceQuery',{
        supplierCode: supplierCode
      }).then(res => {
        if(res.data.length>0) {
          this.brandLoading = true
          setTimeout(()=>{ //高亮选中
            this.$refs.brandTree.setCurrentKey(res.data[0].equipmentBrandCode)
            this.brandLoading = false
          },200)
          this.brandData = res.data
          this.loadSupplierByEquipment(res.data[0].supplierCode, res.data[0].equipmentBrandCode)

          this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
            if (this.tableSelectList.find(item => item.eid == v.eid)) {
              v.checked = true
            }else {
              v.checked = false
            }
          })
        }
      })
    },

    // 获取根据供应商、品牌筛选出的设备
    loadSupplierByEquipment(supplierCode, equipmentBrandCode) {
      this.tableSubDeviceLoading = true
      this.$ajax.post('supplierByEquipment',{
        supplierCode: supplierCode,
        equipmentBrandCode: equipmentBrandCode,
        pageNum: 0,
        pageSize: 0
      }).then(res => {
        res.data.forEach(item => {
          item.checked = false
        })
        this.tableDataCp = res.data
        this.tableSubDeviceLoading = false
        
        this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
          if (this.tableSelectList.find(item => item.eid == v.eid)) {
            v.checked = true
          }else {
            v.checked = false
          }
        })
      }).catch(err=>{
        this.tableSubDeviceLoading = false;
      })
    },

    // 点击供应商子项
    supplierClick(data) {
      this.brandData = []
      this.tableDataCp = []
      this.loadBrand(data.supplierCode)
    },

    // 点击品牌子项
    brandClick(data) {
      this.tableDataCp = []
      this.loadSupplierByEquipment(data.supplierCode, data.equipmentBrandCode)
    },

    // 勾选设备
    checkDeviceChange(row) {
      this.tableSelectArr = []
      this.deviceRadio = row.id
      this.tableSelectArr.push(row)
    },

    // 勾选完毕点击确定
    deviceDrawerOK() {
      if(this.tableSelectArr.length==0) return this.$message.error('请新增一个设备')
      this.tableData = this.tableSelectArr
      this.loadTagsList(this.tableData[0].eid)
      this.deviceDrawer = false

      // let subEid = []
      // this.tableSelectList.forEach(item=>{
      //   subEid.push(item.eid)
      // })

      // let isCludes = subEid.includes(this.eid)
      // if(subEid.length==0) return this.$message.error('请选择需要添加设备')
      // if(isCludes) return this.$message.error('不能添加相同设备')
      
      // this.gatewayBind(this.eid, subEid)
    },

    // 新增自定义属性
    addCustomAttr() {
      this.customAttr.push({
        defaultAttr: '',
        dataContent: [{
          tagDrawer: false,
          tagRadio: '',
          dtype: '',
          formulaType: '',
          boolOperator: 1,
          boolTag: '',
          boolTagEn: '',
          boolUnit: '',
          boolDesc: '',
          boolTag1: '',
          boolTag1En: '',
          tagCustom: [{
            tagDrawer: false,
            tagRadio: '',
            name: ''
          }],
          tagExplain: [{
            boolCode:'',
            boolMeaning:''
          }],
          firstInput:'',
          formulaList: [{
            tagName:'',
            tagDesc:null,
            textName:'',
            tagDrawer: false,
            tagFRadio: '',
          }]
        }]
      })
    },

    // 删除自定义属性
    delCustomAttr(index) {
      this.customAttr.splice(index, 1)
    },

    // 新增数据内容
    addDataContent(arr) {
      arr.push({
        tagDrawer: false,
        tagRadio: '',
        dtype: '',
        formulaType: '',
        boolOperator: 1,
        boolTag: '',
        boolTagEn: '',
        boolUnit: '',
        boolDesc: '',
        boolTag1: '',
        boolTag1En: '',
        tagCustom: [{
          tagDrawer: false,
          tagRadio: '',
          name: ''
        }],
        tagExplain: [{
          boolCode:'',
          boolMeaning:''
        }],
        formulaList: [{
          tagName:'',
          tagDesc:null,
          textName:'',
          tagDrawer: false,
          tagFRadio: '',
        }]
      })
    },

    // 删除数据内容
    delDataContent(arr, index) {
      arr.splice(index, 1)
    },

    // 新增Tag
    addTag(arr) {
      arr.push({
        tagName:'',
        tagDesc:null,
        textName:'',
        tagDrawer: false,
        tagFRadio: '',
      })
    },

    // 删除Tag
    delTag(arr) {
      arr.pop()
    },

    // 新增Tag说明
    addTagExplain(arr) {
      arr.push({
        boolCode:'',
        boolMeaning:''
      })
    },

    // 删除Tag说明
    delTagExplain(arr, index) {
      arr.splice(index, 1)
    },

    // 选择Tag
    selectTag(dItem) {
      dItem.tagDrawer = true
    },
    // 清除Tag
    clearTag(dItem) {
      dItem.boolTag = ''
      dItem.boolTagEn = ''
      dItem.boolTag1 = ''
      dItem.boolTag1En = ''
      dItem.boolDesc = ''
      this.$forceUpdate()
    },
    // 勾选Tag
    checkTagChange(row) {
      this.tagSelectObj = row
    },
    // 运算符循环Tag选择
    selectTagItem(val) {
      val.tagDrawer = true
      this.loadTagsList()
    },
    // 运算符循环Tag清除
    clearTagItem(val) {
      val.boolTag = ''
      val.tagName = ''
      val.tagDesc = ''
      this.$forceUpdate()
    },
    // 运算符循环Tag确认
    formulaDrawerOK(val) {
      val.boolTag = this.tagSelectObj.pointName
      val.boolDesc = this.tagSelectObj.describe
      val.tagName = this.tagSelectObj.pointName
      val.tagDesc = this.tagSelectObj.describe
      val.tagDrawer = false
    },

    // 获取Tag列表
    loadTagsList() {
      this.abilityLoading = true
      this.boolTags = []
      this.$ajax.post("iotTagsQuery", {}, { 
        eid: this.tableData[0].eid
      }).then(res=>{
        this.tagTableData = res.data
        this.abilityLoading = false
      }).catch(err=>{
        this.abilityLoading = false
      })
    },

    // 查询孪生体绑定详情
    loadTwinsData() {
      this.loading = true;
      this.$ajax.post("iotTwinsDetail", {}, { 
        digitalTwinId: this.row.digitalTwinId
      }).then(res=>{
        if(res.data) {
          this.loadIotDevcieDetail(res.data.eid)
        
          this.form.deviceParts = res.data.deviceParts
          let customAttributes = []
          for(let k in res.data.attributeValues) {
            let dataContent = []
            res.data.attributeValues[k].forEach((item,index)=>{
              let boolOperator = null
              let tagDesc = null
              let boolTag = null
              let boolUnit = null
              let boolDesc = null
              let boolTag1 = null
              let formulaType = null
              let firstInput = ''
              let tagCustom = []
              let tagExplain = []
              let formulaList = [{
                tagName:'',
                tagDesc:null,
                textName:'',
                tagDrawer: false,
                tagFRadio: ''
              }]
              if(item.boolData) {
                formulaType = item.boolData.formulaType
                if(item.boolData.formulaType=='default') {
                  boolTag = item.boolData.tag
                  tagDesc = item.boolData.tag
                }else {
                  formula = item.boolData.formula
                }
                for(let values in item.boolData.values) {
                  tagExplain.push({
                    boolCode: values,
                    boolMeaning: item.boolData.values[values]
                  })
                }
                boolUnit = item.boolData.unit
                boolDesc = item.boolData.tagDescribe
              }
              if(item.realData) {
                formulaType = item.realData.formulaType
                if(item.realData.formulaType=='default') {
                  boolTag = item.realData.tag
                  tagDesc = item.realData.tag
                }else {
                  formula = item.realData.formula
                }
                boolUnit = item.realData.unit
                boolDesc = item.realData.tagDescribe

                tagExplain.push({
                  boolCode: '',
                  boolMeaning: ''
                })
              }
              dataContent.push({
                tagDrawer: false,
                tagRadio: '',
                dtype: item.dataType,
                formulaType: formulaType,
                boolOperator: boolOperator,
                tagDesc: tagDesc,
                boolTag: boolTag,
                boolUnit: boolUnit,
                boolDesc: boolDesc,
                boolTag1: boolTag1,
                tagCustom: tagCustom,
                tagExplain: tagExplain,
                firstInput: firstInput,
                formulaList: formulaList
              })
            })
            customAttributes.push({
              defaultAttr: k,
              dataContent: dataContent
            })
          }
          this.customAttr = customAttributes
        }
        this.loading = false
      }).catch(err=>{
        this.loading = false;
      })
    },
    // 查询iot设备详情
    loadIotDevcieDetail(eid) {
      this.$ajax.post("iotEquipmentDetail", {}, { 
        eid: eid
      }).then(res=>{
        this.tableData.push(res.data)
        this.loadTagsList(res.data.eid)
      })
    }
  },
  created() {
    if (this.$route.params.row == null) {
      this.$router.push("/iotTwinsManage");
    } else {
      this.row = this.$route.params.row
    }
    this.loadTwinsData()
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.custom-attr {
  border: 1px solid #ebeef5;
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border-radius: 10px;
  margin-bottom: 20px;
}
.custom-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  font-size: 14px;
  .head-title {
    padding: 0 10px;
    height: 40px;
    background:#1494fb;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    line-height: 40px;
    text-align: center;
    color: #FFF;
  }
}
.head-close {
  width: 25px;
  height: 25px;
  background: #ff7979;
  border-radius: 5px;
  text-align: center;
  line-height: 25px;
  color: #FFF;
  font-size: 22px;
}
.head-close:hover {
  cursor: pointer;
  opacity: .8;
}
.data-content {
  background: #f9f9fa;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  margin: 10px;
  padding-top: 20px;
  padding-right: 10px;
}
.tag-explain {
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin:20px 0px;
}
.tag-explain-title {
  font-size:14px;
  margin-left:40px;
  font-weight:bold;
}
.lube-btn {
    width: 100%;
}
.close-lube-btn {
  display: inline-block;
  text-align: center;
  width: 100%;
  color: #409EFF;
  line-height: 40px;
  border-radius: 4px;
  background: #ecf5ff;
  border-color: #409EFF;
  .close-lube {
    cursor: pointer;
  }
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
.unbind {
  font-size: 14px;
  padding-left: 15px;
}
.formula-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    .formula-title-left::before {
        content: '';
        border-left: 4px solid #409EFF;
        padding-left: 10px;
    }
}
::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; 
  background-color: #c1c1c1;
  /* #58585B */
}
</style>